import React from "react";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import Section from "@components/section";
import { BlockSupTitle, BlockDescription } from "@components/shared.styled";
import {
  StyledBlockTitle,
  StyledWrapper,
  ItemsContainer,
  Item,
  IconContainer,
  Icon,
  ItemTitle,
} from "@modules/benefits/shared.styled";
import { graphql, useStaticQuery } from "gatsby";

const BenefitsBig = () => {
  const {
    markdownRemark: {
      frontmatter: {
        benefitsBigBlock: {
          backgroundColor,
          smallHeader,
          largeHeader,
          resultsList,
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/brands/" }) {
          frontmatter {
            benefitsBigBlock {
              backgroundColor
              smallHeader
              largeHeader
              resultsList {
                alt
                description
                imageUrl {
                  publicURL
                  childImageSharp {
                    fixed(width: 56) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                iconBackgroundColor
                title
              }
            }
          }
        }
      }
    `
  );

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledWrapper>
        {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
        <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
        <ItemsContainer>
          {resultsList.map(
            ({ alt, description, imageUrl, iconBackgroundColor, title }) => (
              <Item key={description}>
                <IconContainer backgroundColor={iconBackgroundColor}>
                  <Icon
                    image={imageUrl}
                    isFixed
                    alt={alt}
                    width="56px"
                    height="56px"
                  />
                </IconContainer>
                {title && <ItemTitle>{title}</ItemTitle>}
                <BlockDescription
                  dangerouslySetInnerHTML={{
                    __html: convertMarkdownToHtml(description),
                  }}
                />
              </Item>
            )
          )}
        </ItemsContainer>
      </StyledWrapper>
    </Section>
  );
};

export default BenefitsBig;
