import React, { useState } from "react";
import * as Colors from "@constants/colors";
import ApproachDiagramImage from "@svg-icons/approach-diagram";
import Collapsible from "@components/collapsible";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Content,
  GraphContainer,
  CollapseContainer,
  CollapseContent,
} from "./proven-approach.styled";
import { graphql, useStaticQuery } from "gatsby";

const titles = [
  "KPI Definition",
  "Audience & Pub Selection",
  "Creative Development",
  "Scheduling & Deployment",
  "Analytics & Optimization",
];

const DiagramCollapse = () => {
  const {
    markdownRemark: {
      frontmatter: {
        approachBlock: { diagramSections },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/brands/" }) {
          frontmatter {
            approachBlock {
              diagramSections {
                description
                labelColor
              }
            }
          }
        }
      }
    `
  );
  const [activeItem, setActiveItem] = useState(null);
  const defaultDiagramColors = diagramSections.map(({ labelColor }) => ({
    labelColor,
  }));
  let diagramColors = defaultDiagramColors.map((arrow, index) => {
    if (activeItem) {
      return {
        labelColor:
          activeItem - 1 === index
            ? defaultDiagramColors[activeItem - 1].labelColor
            : Colors.white,
      };
    }

    return { labelColor: defaultDiagramColors[index].labelColor };
  });
  const data = titles.map((title, index) => ({
    id: index + 1,
    title,
    labelColor: defaultDiagramColors[index].labelColor,
    content: (
      <CollapseContent
        dangerouslySetInnerHTML={{
          __html: convertMarkdownToHtml(diagramSections[index].description),
        }}
      />
    ),
  }));

  return (
    <Content>
      <GraphContainer>
        <ApproachDiagramImage diagramColors={diagramColors} />
      </GraphContainer>
      <CollapseContainer>
        <Collapsible data={data} setActiveItem={setActiveItem} />
      </CollapseContainer>
    </Content>
  );
};

export default DiagramCollapse;
