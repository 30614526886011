import React from "react";

const PhoneMarks = ({
  width = "411",
  height = "390",
  messages: { bottomLeft, bottomRight, topLeft, topRight },
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 549 521"
  >
    <g transform="translate(-671 -270)">
      <g transform="translate(77 250)">
        <g transform="translate(1936.041 681) rotate(180)">
          <circle
            cx="5.5"
            cy="5.5"
            r="5.5"
            transform="translate(923.041 421.996)"
            fill="#333"
          />
          <line
            x1="65.041"
            transform="translate(863.5 427.5)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
        </g>
        <text
          transform="translate(1084 259)"
          fill="#333"
          fontSize="14"
          fontFamily="ProximaNova"
        >
          <tspan x="0" y="0">
            {topRight.line1}
          </tspan>
          <tspan x="0" y="17.5">
            {topRight.line2}
          </tspan>
          <tspan x="0" y="35">
            {topRight.line3}
          </tspan>
        </text>
      </g>
      <g transform="translate(15 221)">
        <g transform="translate(1995 972) rotate(180)">
          <circle
            cx="5.5"
            cy="5.5"
            r="5.5"
            transform="translate(934 422)"
            fill="#333"
          />
          <line
            x1="73"
            transform="translate(863.5 427.5)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
        </g>
        <text
          transform="translate(1146 549)"
          fill="#333"
          fontSize="14"
          fontFamily="ProximaNova"
        >
          <tspan x="0" y="0">
            {bottomRight.line1}
          </tspan>
          <tspan x="0" y="17.5">
            {bottomRight.line2}
          </tspan>
          <tspan x="0" y="35">
            {bottomRight.line3}
          </tspan>
        </text>
      </g>
      <g transform="translate(-85 -125)">
        <g transform="translate(10 -27)">
          <circle
            cx="5.5"
            cy="5.5"
            r="5.5"
            transform="translate(943 422)"
            fill="#333"
          />
          <line
            x1="112"
            transform="translate(833.5 427.5)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
        </g>
        <text
          transform="translate(756 406)"
          fill="#333"
          fontSize="14"
          fontFamily="ProximaNova"
        >
          <tspan x="0" y="0">
            {topLeft.line1}
          </tspan>
          <tspan x="0" y="17.5">
            {topLeft.line2}
          </tspan>
          <tspan x="0" y="35">
            {topLeft.line3}
          </tspan>
        </text>
      </g>
      <g transform="translate(-85 -37)">
        <g transform="translate(-19 262)">
          <circle
            cx="5.5"
            cy="5.5"
            r="5.5"
            transform="translate(950 422)"
            fill="#333"
          />
          <line
            x1="92"
            transform="translate(863.5 427.5)"
            fill="none"
            stroke="#333"
            strokeWidth="1"
          />
        </g>
        <text
          transform="translate(756 695)"
          fill="#333"
          fontSize="14"
          fontFamily="ProximaNova"
        >
          <tspan x="0" y="0">
            {bottomLeft.line1}
          </tspan>
          <tspan x="0" y="17.5">
            {bottomLeft.line2}
          </tspan>
          <tspan x="0" y="35">
            {bottomLeft.line3}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);

export default PhoneMarks;
