import React, { createRef, useEffect } from "react";
import * as Colors from "@constants/colors";
import ScreenImageUrl from "@images/hrblock-screen-photo.png";
import PhoneFrame from "@svg-icons/phone-without-marks";
import PhoneMarks from "@svg-icons/phone-marks";
import ButtonLink from "@components/button-link";
import { applyHighlightAnimation } from "@helpers/animations";
import Section from "@components/section";
import { Wrapper, BlockSupTitle } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Content,
  LargeHeader,
  Info,
  InfoBlock,
  PhoneContainer,
  ScreenImage,
  PhoneMarksContainer,
  Highlight,
} from "./potential.styled";

const Potential = ({ data }) => {
  const {
    backgroundColor,
    buttonLabel,
    description,
    largeHeader,
    highlightColor,
    messages,
    smallHeader,
  } = data;

  const highlights = largeHeader.filter(({ isHighlighted }) => isHighlighted);
  const highlightRefs = highlights.map(() => createRef());
  let highlightCount = -1;

  useEffect(() => {
    const tweens = highlightRefs.map(ref => {
      return applyHighlightAnimation(
        ref.current,
        highlightColor || Colors.goldenHourLight
      );
    });

    return () => {
      tweens.forEach(tween => tween.kill());
    };
  });

  return (
    <Section backgroundColor={backgroundColor} justify="left">
      <Wrapper>
        <Content>
          <InfoBlock>
            {smallHeader && (
              <BlockSupTitle color={Colors.charcoal}>
                {smallHeader}
              </BlockSupTitle>
            )}
            <LargeHeader>
              {largeHeader.map(({ text, isHighlighted }) => {
                if (isHighlighted === true) {
                  highlightCount += 1;
                  return (
                    <Highlight
                      ref={highlightRefs[highlightCount]}
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                      highlightColor={highlightColor}
                    />
                  );
                } else {
                  return (
                    <span
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  );
                }
              })}
            </LargeHeader>
            <Info
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
            <ButtonLink to="/contact" text={buttonLabel} />
          </InfoBlock>
          {messages && (
            <PhoneContainer>
              <PhoneFrame />
              <ScreenImage src={ScreenImageUrl} alt="screen image" />
              <PhoneMarksContainer>
                <PhoneMarks messages={messages} />
              </PhoneMarksContainer>
            </PhoneContainer>
          )}
        </Content>
      </Wrapper>
    </Section>
  );
};

export default Potential;
