import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import Image from "@components/image";
import { Wrapper } from "@components/shared.styled";

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${Screens.lg}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const GraphContainer = styled.div`
  width: 480px;
  height: 480px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    width: 100%;
  }

  @media (max-width: ${Screens.lg}) {
    margin: 0 auto;
    width: 100%;
    max-width: 408px;
    max-height: 408px;
    flex-direction: column;
    justify-content: center;
  }
`;

const CollapseContainer = styled.div`
  color: ${Colors.offWhite};
  max-width: 408px;
  text-align: left;
  margin: 0 auto;

  button {
    color: ${Colors.offWhite};
  }
`;

const CollapseContent = styled.div`
  margin: 16px 0 0;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: ${Screens.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const StyledWrapper = styled(Wrapper)`
  text-align: center;
`;

const GraphImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${Screens.lg}) {
    display: none;
  }
`;

export {
  Content,
  GraphContainer,
  CollapseContainer,
  CollapseContent,
  StyledWrapper,
  GraphImage,
};
