import React from "react";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import Section from "@components/section";
import { BlockSupTitle, BlockDescription } from "@components/shared.styled";
import {
  StyledBlockTitle,
  StyledWrapper,
  ItemsContainer,
  Item,
  IconContainer,
  Icon,
  ItemTitle,
  StyledList,
  StyledListItem,
} from "@modules/benefits/shared.styled";

const BenefitsSmall = ({
  data: { backgroundColor, textColor, smallHeader, largeHeader, resultsList },
  pageType,
  className = "",
}) => (
  <Section backgroundColor={backgroundColor} className={className}>
    <StyledWrapper textColor={textColor}>
      {smallHeader && <BlockSupTitle>{smallHeader}</BlockSupTitle>}
      {largeHeader && <StyledBlockTitle>{largeHeader}</StyledBlockTitle>}
      <ItemsContainer>
        {resultsList.map(
          ({ alt, description, stringList, imageUrl, iconBackgroundColor, title }) => (
            <Item key={description || stringList[0].item}>
              {imageUrl && (
                <IconContainer backgroundColor={iconBackgroundColor}>
                  <Icon
                    image={imageUrl}
                    isFixed
                    alt={alt}
                    width="56px"
                    height="56px"
                  />
                </IconContainer>
              )}
              {title && <ItemTitle pageType={pageType}>{title}</ItemTitle>}
              <BlockDescription
                pageType={pageType}
                dangerouslySetInnerHTML={{
                  __html: convertMarkdownToHtml(description),
                }}
              />
              {stringList?.length && (
                <StyledList>
                  {stringList.map(({ item }) => (
                    <StyledListItem key={item}>{item}</StyledListItem>
                  ))}
                </StyledList>
              )}
            </Item>
          )
        )}
      </ItemsContainer>
    </StyledWrapper>
  </Section>
);

export default BenefitsSmall;
