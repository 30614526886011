import styled from "styled-components";
import { Screens } from "@constants/screens";
import { BlockTitle, BlockDescription } from "@components/shared.styled";
import Image from "@components/image";
import * as Colors from "@constants/colors";

const Container = styled.section`
  background-color: ${({ containerBackground = Colors.goldenHourLighter }) =>
    containerBackground};
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: ${Screens.md}) {
    text-align: center;
    flex-wrap: wrap;
  }
`;

const StyledImage = styled(Image)`
  height: 100%;
`;

const ImageContainer = styled.div`
  max-width: 50vw;
  width: 100%;
  display: flex;
  align-items: stretch;

  @media (max-width: ${Screens.xl}) {
    max-width: 56.5vw;
  }

  @media (max-width: ${Screens.lg}) {
    max-width: 60vw;
    text-align: center;
    flex-wrap: wrap;
  }

  @media (max-width: ${Screens.md}) {
    order: 1;
    max-width: 100%;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 582px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${Screens.md}) {
    max-width: 100%;
    justify-content: flex-start;
  }
`;

const DescriptionBlock = styled.div`
  width: 100%;
  padding: 0 40px 0 20px;

  @media (max-width: ${Screens.lg}) {
    max-width: 100%;
    padding: 0 40px 0 20px;
  }
`;

const StyledBlockDescription = styled(BlockDescription)`
  max-width: 440px;
  @media (max-width: ${Screens.md}) {
    line-height: 1.5;
    margin: 0 auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 34px;

  @media (max-width: ${Screens.lg}) {
    justify-content: center;
  }
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.25;

    @media (max-width: ${Screens.lg}) {
    font-size: 28px;
    line-height: 1.25;
  }
`;

export {
  Container,
  StyledImage,
  ImageContainer,
  Content,
  ContentWrapper,
  DescriptionBlock,
  StyledBlockTitle,
  StyledBlockDescription,
  ButtonContainer,
};
