import styled, { css } from "styled-components";
import * as Colors from "@constants/colors";
import * as FONTS from "@constants/fonts";
import { Screens } from "@constants/screens";
import Image from "@components/image";
import { Wrapper } from "@components/shared.styled";
import { BlockTitle } from "@components/shared.styled";

const Container = styled.section`
  padding: 112px 0 0;
  text-align: center;
  font-weight: bold;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Item = styled.div`
  max-width: 272px;
  width: 90%;
  margin: 60px 36px 0;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${Screens.md}) {
    max-width: 248px;
  }

  @media (max-width: ${Screens.sm}) {
    max-width: 100%;
  }
`;

const IconContainer = styled.div`
  background-color: ${({ backgroundColor = Colors.seafoamLighter }) =>
    backgroundColor};
  border-radius: 50%;
  width: 144px;
  height: 144px;
  margin: 0 auto 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Screens.lg}) {
    width: 128px;
    height: 128px;
  }
`;

const Icon = styled(Image)`
  max-height: 56px;

  @media (max-width: ${Screens.lg}) {
    max-width: 50px;
    max-height: 50px;
  }
`;

const ItemTitle = styled.h3`
  ${({ pageType }) => pageType === 'careers' ? css`
    font-size: 24px;
    font-family: ${FONTS.ClearfaceFont};

    @media (max-width: ${Screens.lg}) {
      font-size: 22px;
    }
  ` : css`
    font-size: 20px;
  `}
  letter-spacing: 0.42px;
  margin: 0 auto 24px;
`;

const StyledWrapper = styled(Wrapper)`
  text-align: center;
  ${({ textColor }) => textColor && css`
    color: ${textColor};
  `};
`;

const StyledBlockTitle = styled(BlockTitle)`
  margin-bottom: 32px;
  line-height: 1.3;
  color: inherit;

  @media (max-width: ${Screens.md}) {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const StyledListItem = styled.li`
  &:not(:last-child) {
    margin: 0 0 20px;
  }
`;

export {
  Container,
  ItemsContainer,
  Item,
  IconContainer,
  Icon,
  ItemTitle,
  StyledWrapper,
  StyledBlockTitle,
  StyledList,
  StyledListItem,
};
