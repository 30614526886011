import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "@components/layout";
import Potential from "@modules/potential";
import BenefitsSmall from "@modules/benefits/benefits-small";
import ProvenApproach from "@modules/proven-approach";
import BenefitsBig from "@modules/benefits/benefits-big";
import BrandRelevance from "@modules/brand-relevance";
import GetStarted from "@modules/get-started";

const Brands = () => {
  const {
    markdownRemark: {
      frontmatter: {
        getStarted,
        pageName,
        introBlock,
        benefitsSmallBlock,
        brandRelevance,
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/brands/" }) {
          frontmatter {
            getStarted {
              backgroundColor
              blockBackground
              buttonLabel
              message
            }
            pageName
            introBlock {
              backgroundColor
              buttonLabel
              description
              largeHeader {
                isHighlighted
                text
              }
              messages {
                bottomLeft {
                  line1
                  line2
                  line3
                }
                bottomRight {
                  line1
                  line2
                  line3
                }
                topLeft {
                  line1
                  line2
                  line3
                }
                topRight {
                  line1
                  line2
                  line3
                }
              }
              smallHeader
            }
            benefitsSmallBlock {
              backgroundColor
              smallHeader
              largeHeader
              resultsList {
                alt
                description
                imageUrl {
                  publicURL
                  childImageSharp {
                    fixed(width: 56) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                iconBackgroundColor
                title
              }
            }
            brandRelevance {
              backgroundColor
              smallHeader
              largeHeader
              description
              buttonLabel
              imageUrl {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <Potential data={introBlock} />
      <BenefitsSmall data={benefitsSmallBlock} />
      <ProvenApproach />
      <BenefitsBig />
      <BrandRelevance data={brandRelevance} />
      <GetStarted {...getStarted} />
    </Layout>
  );
};

export default Brands;
