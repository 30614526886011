import styled, { keyframes } from "styled-components";
import { Screens } from "@constants/screens";
import * as Fonts from "@constants/fonts";
import * as Colors from "@constants/colors";
import { ButtonLink } from "@components/button-link/button-link.styled";

const Container = styled.section`
  background: ${Colors.goldenHour};
  padding: 112px 0;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const slideAnimation = keyframes`
  0% { opacity: 0; transform: translateX(64px); }
  100% { opacity: 1.0; transform: translateX(0); }
`;

const fadeInAnimation = keyframes`
  0% { opacity: 0; transform: scale(1.1); }
  50% { opacity: 0; transform: scale(1.1); }
  100% { opacity: 1.0; transform: scale(1.0); }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ButtonLink} {
    color: ${Colors.white};
    border-color: ${Colors.charcoal};
    background: ${Colors.charcoal};

    :hover {
      background: ${Colors.blackLighter};
      border-color: ${Colors.blackLighter};
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const InfoBlock = styled.div`
  max-width: 40%;
  margin-right: 10px;

  @media (max-width: 1100px) {
    text-align: center;
    max-width: 100%;
    margin: 0;
  }
`;

const LargeHeader = styled.h1`
  display: inline-block;
  font-family: ${Fonts.ClearfaceFont};
  font-size: 48px;
  line-height: 62px;
  margin: 0 0 5px;

  @media (max-width: ${Screens.lg}) {
    font-size: 36px;
    line-height: 44px;
  }

  span {
    padding-right: 10px;
  }
`;

const Highlight = styled.span`
  background: linear-gradient(
    to right,
    ${({ highlightColor }) => highlightColor || Colors.goldenHourLight} 100%,
    transparent 0%
  );
  text-decoration: underline;
  padding: 0 10px;
`;

const Info = styled.div`
  font-size: 20px;
  line-height: 28px;
  width: 100%;
  margin: 24px 0 48px;
  max-width: 470px;

  @media (max-width: 1100px) {
    max-width: 100%;
    font-size: 22px;
    line-height: 30px;
    margin: 20px auto 40px;
  }
`;

const PhoneContainer = styled.div`
  min-width: 45%;
  display: flex;
  justify-content: center;
  position: relative;
  animation: ${slideAnimation} 0.5s 1;

  @media (max-width: 1100px) {
    min-width: auto;
    margin: 0 auto;
    max-width: 100%;
  }

  @media (max-width: ${Screens.md}) {
    max-width: 280px;
    display: none;
  }
`;

const ScreenImage = styled.img`
  position: absolute;
  top: 32px;
  width: 100%;
  max-width: 222px;
  border-radius: 14px;

  @media (max-width: ${Screens.md}) {
    width: 87%;
  }
`;

const PhoneMarksContainer = styled.div`
  position: absolute;
  top: 64px;
  left: 30px;
  animation: ${fadeInAnimation} 1s 1;

  @media (max-width: 1100px) {
    left: 43%;
    transform: translateX(-50%);
  }

  @media (max-width: ${Screens.md}) {
    display: none;
  }
`;

export {
  Container,
  Content,
  InfoBlock,
  LargeHeader,
  Info,
  PhoneContainer,
  ScreenImage,
  PhoneMarksContainer,
  Highlight,
};
