import React from "react";

const PhoneFrame = ({ width = "253", height = "523" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 338 698"
  >
    <g transform="translate(0.01 0)">
      <rect
        width="8"
        height="100"
        rx="4"
        transform="translate(329.99 133)"
        fill="#2b2627"
      />
      <rect
        width="8"
        height="55"
        rx="4"
        transform="translate(-0.01 121)"
        fill="#2b2627"
      />
      <rect
        width="8"
        height="54"
        rx="4"
        transform="translate(-0.01 186)"
        fill="#2b2627"
      />
      <g transform="translate(3.303 0)">
        <rect
          width="332"
          height="698"
          rx="24"
          transform="translate(-0.312 0)"
          fill="#2b2627"
        />
        <g transform="translate(124.407 19.817)">
          <rect
            width="70"
            height="8"
            rx="4"
            transform="translate(0.281 0.183)"
            fill="#fff"
          />
          <rect
            width="7"
            height="8"
            rx="3.5"
            transform="translate(76.281 0.183)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PhoneFrame;
