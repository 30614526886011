import React from "react";
import * as Colors from "@constants/colors";
import Section from "@components/section";
import { BlockSupTitle, BlockTitle } from "@components/shared.styled";
import { StyledWrapper } from "./proven-approach.styled";
import DiagramCollapse from "./diagram-collapse";
import { graphql, useStaticQuery } from "gatsby";

const ProvenApproach = () => {
  const {
    markdownRemark: {
      frontmatter: {
        approachBlock: { backgroundColor, smallHeader, largeHeader },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/brands/" }) {
          frontmatter {
            approachBlock {
              backgroundColor
              smallHeader
              largeHeader
            }
          }
        }
      }
    `
  );

  return (
    <Section backgroundColor={backgroundColor}>
      <StyledWrapper>
        <BlockSupTitle>{smallHeader}</BlockSupTitle>
        <BlockTitle color={Colors.white}>{largeHeader}</BlockTitle>
        <DiagramCollapse />
      </StyledWrapper>
    </Section>
  );
};

export default ProvenApproach;
