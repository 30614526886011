import React, { useRef, useEffect } from "react";
import { applyFadeInAnimation } from "@helpers/animations";
import ButtonLink from "@components/button-link";
import { BlockSupTitle } from "@components/shared.styled";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import {
  Container,
  ImageContainer,
  StyledImage,
  Content,
  ContentWrapper,
  DescriptionBlock,
  StyledBlockTitle,
  StyledBlockDescription,
  ButtonContainer,
} from "./brand-relevance.styled";

const BrandRelevance = ({ data, imageMaxHeight = "auto" }) => {
  const {
    backgroundColor,
    smallHeader,
    smallHeaderColor,
    largeHeader,
    description,
    buttonLabel,
    imageUrl,
    imageAlt,
  } = data;

  const fadeInElementRef = useRef(null);

  useEffect(() => {
    const { timeline, scrollTriggerInstance } = applyFadeInAnimation(
      fadeInElementRef
    );
    return () => {
      timeline.pause(0).kill();
      scrollTriggerInstance.kill(true);
    };
  });

  return (
    <Container ref={fadeInElementRef} containerBackground={backgroundColor}>
      <Content>
        <ContentWrapper>
          <DescriptionBlock>
            <BlockSupTitle color={smallHeaderColor}>
              {smallHeader}
            </BlockSupTitle>
            <StyledBlockTitle>{largeHeader}</StyledBlockTitle>
            <StyledBlockDescription
              dangerouslySetInnerHTML={{
                __html: convertMarkdownToHtml(description),
              }}
            />
            {buttonLabel && (
              <ButtonContainer>
                <ButtonLink text={buttonLabel} to="/contact" />
              </ButtonContainer>
            )}
          </DescriptionBlock>
        </ContentWrapper>
      </Content>
      <ImageContainer>
        <StyledImage width="100%" height="100%" image={imageUrl} alt={imageAlt} style={{ maxHeight: imageMaxHeight }} />
      </ImageContainer>
    </Container>
  );
};

export default BrandRelevance;
