import React from "react";

const ApproachDiagram = ({ diagramColors }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 800 800"
  >
    <g>
      <g>
        <path
          fill={diagramColors[4].labelColor}
          d="M171.8,86c-66.7,48.5-115.5,115.6-141,194l0,0L96,269.5l48,46.3c17.8-54,51.5-100.2,97.5-133.7
			c42.6-30.9,92.3-48.4,144.5-51.1l30-59.9l-29.4-58.9C309,14.8,235,40.1,171.8,86z"
        />
      </g>
      <path
        fill={diagramColors[0].labelColor}
        d="M764.6,267.6C738.1,194.5,691.1,132,628,86.1C562.4,38.4,485.1,12.9,404.2,12L433.7,71l-29.8,59.6
		c55.8,0.8,109.1,18.6,154.3,51.4c42.6,30.9,74.6,72.8,93.2,121.6l66.3,10L764.6,267.6z"
      />
      <path
        fill={diagramColors[2].labelColor}
        d="M399.8,788c81,0,158.6-24.7,224.6-71.6l-58.6-30.4l-10.9-65.8c-45.6,32.1-99.2,49.1-155.1,49.1
		c-52.6,0-103.1-15-146.8-43.6l-59.5,30.9l-10.8,64.9C247,765.1,321.8,788,399.8,788z"
      />
      <path
        fill={diagramColors[3].labelColor}
        d="M30.8,519.8c25,77.1,72.6,143.2,137.5,191.5l10.8-65.1l59.2-30.7c-44.7-33.5-77.4-79.1-94.7-132.4
		c-15.9-49.1-17.5-100.7-4.7-150.3l-48.3-46.5l-65,10.5C5.4,370.3,7.1,447,30.8,519.8z M30.8,280L30.8,280L30.8,280
		C30.8,280,30.8,280,30.8,280z"
      />
      <g>
        <path
          fill="#231F20"
          d="M513.1,93.3l-1.9,1l-1.9,5.1l-3.5-1.3l6.2-16.5l3.5,1.3l-2.8,7.4l8.6-5.2l4.3,1.6l-9.4,5.3l3.7,11.4l-4.3-1.6
			L513.1,93.3z"
        />
        <path
          fill="#231F20"
          d="M528.1,87.7l7.6,3.3c3.5,1.5,4.4,4.7,3.2,7.6c-1.2,2.8-4.2,4.3-7.7,2.8l-4.1-1.8l-2.5,5.8l-3.4-1.5
			L528.1,87.7z M533.9,93.8l-3.7-1.6l-1.9,4.5l3.7,1.6c1.4,0.6,2.7,0.2,3.3-1.2C535.9,95.7,535.3,94.3,533.9,93.8z"
        />
        <path
          fill="#231F20"
          d="M543.4,94.5l3.4,1.6l-7.6,15.9l-3.4-1.6L543.4,94.5z"
        />
        <path
          fill="#231F20"
          d="M556.5,100.9l6.1,3.4c4.8,2.7,6.5,7.6,3.9,12.2c-2.6,4.6-7.6,5.8-12.4,3.2l-6.1-3.4L556.5,100.9z
			 M555.6,116.8c3,1.7,6,0.5,7.5-2.1c1.5-2.7,1-5.7-2.2-7.5l-2.8-1.5l-5.3,9.6L555.6,116.8z"
        />
        <path
          fill="#231F20"
          d="M573.4,110.4l10.6,6.6l-1.7,2.7l-7.4-4.6l-2,3.2l7.2,4.5l-1.7,2.7l-7.2-4.5l-2.2,3.5l7.4,4.6l-1.7,2.8
			l-10.6-6.6L573.4,110.4z"
        />
        <path
          fill="#231F20"
          d="M586.7,118.9l10.2,7.1l-1.8,2.6l-7.2-4.9l-2.2,3.1l7,4.8l-1.8,2.6l-7-4.8l-4.2,6l-3.1-2.1L586.7,118.9z"
        />
        <path
          fill="#231F20"
          d="M599.3,127.7l3,2.2l-10.5,14.1l-3-2.2L599.3,127.7z"
        />
        <path
          fill="#231F20"
          d="M604.7,139l-7.4,9.2l-2.9-2.3l11-13.7l3,2.4l-0.8,14l7.1-8.9l2.9,2.3l-11,13.7l-2.8-2.3L604.7,139z"
        />
        <path
          fill="#231F20"
          d="M620.5,144.6l2.8,2.5l-11.6,13.2l-2.8-2.5L620.5,144.6z"
        />
        <path
          fill="#231F20"
          d="M626.9,154.7l-3.8-3.5l2.3-2.4l10.2,9.6l-2.3,2.4l-3.7-3.5l-9.8,10.4l-2.8-2.6L626.9,154.7z"
        />
        <path
          fill="#231F20"
          d="M637.5,160.4l2.6,2.7l-12.5,12.4l-2.6-2.7L637.5,160.4z"
        />
        <path
          fill="#231F20"
          d="M648.8,171.7c3.6,3.9,3.4,9.3-0.5,12.9c-3.9,3.6-9.3,3.3-12.9-0.6c-3.6-3.9-3.4-9.3,0.5-12.9
			C639.8,167.5,645.2,167.8,648.8,171.7z M646.3,173.9c-2.2-2.4-5.4-2.2-7.9,0c-2.4,2.2-2.8,5.4-0.7,7.8c2.2,2.4,5.5,2.3,7.9,0
			C648.1,179.5,648.5,176.3,646.3,173.9z"
        />
        <path
          fill="#231F20"
          d="M654.7,187.6l-9.2,7.5l-2.4-2.9l13.7-11.1l2.4,3l-3.7,13.5l8.8-7.2l2.4,2.9l-13.7,11.1l-2.3-2.8L654.7,187.6z
			"
        />
      </g>
      <path
        fill="#231F20"
        d="M126.4,541.6l-2.7,1.5l4,7.3l-3.4,1.9l-3.9-7.1l-2.7,1.5l3.9,7.1l-3.2,1.7l-4-7.3l-2.7,1.5l5.7,10.4l14.7-8
		L126.4,541.6z M83.7,473.7l11.1,1.4l-9.1,6.4l1.2,4.8l16.2-4l-0.9-3.5l-11.7,2.9l10.6-7.5l-0.4-1.5L88,471.2l11.7-2.9l-0.9-3.5
		l-16.2,4L83.7,473.7z M87.5,488.6l1.2,3.9l7.6,1.9l-5.2,6l1.2,3.9l7.5-9.1l6.5-2l-1.1-3.4l-6.5,2L87.5,488.6z M80,432.1l-0.4-4.9
		l13.5-1.1l-0.3-3.6l-13.5,1.1l-0.4-4.8l-3.1,0.2l1.1,13.3L80,432.1z M77.6,438.7l10.7-1.4l-9.7,9.1l0.5,3.6l16.6-2.2l-0.5-3.5
		L84,445.7l10.1-9.4l-0.5-3.4l-16.6,2.2L77.6,438.7z M94.6,459l-3.8,0.7l-1.5-8l-3,0.6l1.5,8l-3.6,0.7l-1.5-8.1l-3,0.6l2.2,11.6
		l16.4-3.1l-2.2-11.6l-3,0.6L94.6,459z M114.4,523.4l-12.5,5.3l1.4,3.3l15.4-6.6l-4.2-9.8l-2.9,1.2L114.4,523.4z M116.5,534.9
		c-1.6-3.3-4.6-4-7.3-2.7c-2.6,1.3-4,4.1-2.4,7.4l3.4,7l15.1-7.3l-1.5-3.2l-5.4,2.6L116.5,534.9z M111.4,542.1l-1.6-3.4
		c-0.6-1.3-0.2-2.6,1-3.2c1.3-0.6,2.5-0.1,3.1,1.2l1.6,3.4L111.4,542.1z M137.8,561.5c-2.7-4.5-7.5-5.8-11.8-3.2
		c-4.3,2.6-5.2,7.5-2.5,11.9l3.4,5.6l14.3-8.7L137.8,561.5z M127.8,571.2l-1.6-2.6c-1.8-2.9-0.8-5.7,1.7-7.2
		c2.4-1.5,5.5-1.1,7.2,1.7l1.6,2.6L127.8,571.2z M100.3,501.6c-4.7,1.8-6.8,6.5-5.1,11.2c1.8,4.7,6.4,6.9,11.1,5.2
		c4.7-1.8,6.8-6.5,5.1-11.2C109.7,502,105,499.8,100.3,501.6z M108.5,507.9c1.1,2.9-0.5,5.6-3.4,6.7c-2.9,1.1-5.8,0.1-6.9-2.8
		c-1.1-2.9,0.4-5.6,3.4-6.7C104.5,503.9,107.4,505,108.5,507.9z M105.6,565.6c-1.9-3.3-4.7-4.1-7.1-4.2l0.2,3.4
		c1.5-0.1,3.2,0.7,4.1,2.3c1.4,2.5,0.5,5.6-2.3,7.2c-2.7,1.6-5.9,0.8-7.3-1.7c-0.9-1.6-0.7-3.5,0.1-4.7l-2.8-1.9
		c-1.2,2.1-1.9,4.9,0,8.2c2.4,4.3,7.4,5.9,11.8,3.4C106.9,575,108,569.9,105.6,565.6z M99.2,554.3l-6.3,3.1l-3.5-7.1l6.3-3.1
		l-1.6-3.2l-15,7.5l1.6,3.2l5.9-2.9l3.5,7.1l-5.9,2.9l1.6,3.2l15-7.4L99.2,554.3z M78.4,503.2c-1.5-4.8-5-6.2-8.9-5l-9.6,3l1.1,3.4
		l9.5-3c2.2-0.7,4.1,0,4.9,2.5c0.8,2.5-0.4,4.2-2.6,4.9l-9.5,3l1.1,3.4l9.6-3C77.9,511.2,79.9,508,78.4,503.2z M84.4,521.3
		c-1.9-4.9-6.3-7.1-11-5.3c-4.7,1.8-6.5,6.4-4.7,11.3l2.4,6.2l15.6-6L84.4,521.3z M72.8,528.9l-1.1-2.8c-1.2-3.2,0.3-5.7,3-6.8
		c2.6-1,5.6-0.1,6.7,3l1.1,2.8L72.8,528.9z M72.1,482.6l-3,0.8l1.8,6.8l-13.1,3.5l0.9,3.4l16.1-4.4L72.1,482.6z M87.9,530.1
		l-2.8,1.2l3.3,7.6l-3.6,1.6l-3.3-7.4l-2.8,1.2l3.3,7.4l-3.3,1.5l-3.3-7.6l-2.8,1.2l4.8,10.8l15.3-6.7L87.9,530.1z M69.2,469.8
		l-11,2.2l9.5-10l-0.7-3.4l-16.4,3.3l0.7,3.5l10.6-2.1l-9.1,9.7l0.7,3.6l16.4-3.3L69.2,469.8z M53.7,428.9c1.1,1.8,2.5,3.4,5.1,3.3
		c3.2-0.2,4.6-2.8,4.4-5.9c-0.1-1.8-0.8-3.2-1.9-4.4c0.4-0.5,0.9-1,1.2-1.3l-0.3-4c-1.1,1.2-2.1,2.4-3.1,3.4c-1.3-0.9-2.8-1.5-4.2-2
		l-1,2.6c1,0.2,2.2,0.7,3.3,1.3c-0.5,0.5-1,1-1.6,1.5c-0.5,0.5-1.1,1-1.7,1.6c-1.1-2-2.3-4-4.9-3.8c-2.2,0.2-3.4,2.3-3.2,4.7
		c0.2,2.8,2.1,4.6,4.3,4.5C51.4,430.2,52.5,429.6,53.7,428.9z M57.7,425.7c0.5-0.4,1.2-1.2,2-2c0.6,0.7,1,1.5,1,2.3
		c0.1,1.4-0.8,2.6-2.4,2.8c-1.1,0.1-2-0.5-2.7-1.3C56.3,426.9,57.1,426.3,57.7,425.7z M48.2,425.4c-0.1-0.8,0.4-1.5,1.3-1.6
		c1.2-0.1,2,0.9,2.8,2.3c-0.7,0.5-1.5,0.8-2.2,0.9C49,427.1,48.2,426.4,48.2,425.4z M58.2,457.2c5.2-0.7,8-5.2,7.3-10
		c-0.4-3-2-5.2-4.1-6.6l-6.3,0.9l1.1,7.9l3-0.4l-0.6-4.4l2-0.3c0.7,0.6,1.6,1.9,1.8,3.4c0.4,3-1.6,5.5-4.7,6
		c-3.1,0.4-5.7-1.4-6.2-4.4c-0.2-1.7,0.5-3.3,1.5-4.2l-2-2.7c-1.8,1.5-3.1,3.9-2.6,7.4C49.1,454.4,53,457.9,58.2,457.2z
		 M106.4,577.6c-3.1,2-2,5.4-1,8.1c0.6,1.9,1.1,3.2,0.2,3.8c-0.8,0.5-1.9,0.2-2.8-1.2c-0.9-1.4-1.3-3.2-1-5l-3.3-0.3
		c-0.4,2.2,0.1,4.5,1.5,6.7c2.1,3.3,5.3,4.2,7.6,2.7c3.2-2,1.9-5.4,1-8c-0.7-1.9-1.1-3.3,0-4c0.8-0.5,2.1-0.2,3.1,1.5
		c1.2,1.9,1.3,4,0.9,5.7l3.3,0.2c0.5-2.2,0.2-4.7-1.5-7.4C112,576.6,108.9,576,106.4,577.6z M70.7,476.6l-16.3,3.9l0.8,3.5l16.3-3.9
		L70.7,476.6z"
      />
      <path
        fill={diagramColors[1].labelColor}
        d="M580.2,675.7l58.3,30.3c61.3-47.7,106.2-111.8,130.4-186c25.1-77.1,25.5-158.5,1.3-235.7l-47,46.3l-66-10
		c16.5,53.3,16.1,109.5-1.2,162.7c-16.3,50-46.1,93.4-86.8,126.2L580.2,675.7z"
      />
      <path
        fill="#231F20"
        d="M411.1,705.7l0.6,13.6l3.6-0.2l-0.6-13.6l4.9-0.2l-0.1-3.1l-13.3,0.6l0.1,3.1L411.1,705.7z M438.8,717.1
		l4.5-17.3l-4,0.5l-3.1,13.3l-6.1-12.2l-4,0.5l8.3,15.9L438.8,717.1z M370.3,701.3l-7.8-0.8l-1.7,16.6l3.5,0.4l0.6-6l2.6,0.3
		l2.7,6.3l4.1,0.4l-3.1-6.7c1.8-0.2,3.8-1.6,4.1-4.6C375.7,704.1,373.8,701.7,370.3,701.3z M371.8,706.8c-0.1,1.4-1.3,2.2-2.7,2
		l-3.7-0.4l0.5-4.6l3.7,0.4C370.9,704.4,371.9,705.4,371.8,706.8z M389.2,716.3l-8.3-0.4l0.2-3.9l8.1,0.4l0.2-3.1l-8.1-0.4l0.2-3.6
		l8.3,0.4l0.2-3.1l-11.8-0.6l-0.9,16.7l11.8,0.6L389.2,716.3z M397,702.7l-6.6,16.7l3.9,0l1.1-2.9l7.5,0l1,2.9l3.9,0l-6.3-16.8
		L397,702.7z M396.4,713.5l2.8-7.6l2.7,7.7L396.4,713.5z M424.9,701.7l-3.5,0.3l1.3,16.7l3.5-0.3L424.9,701.7z M458.7,714.1l-0.5-3
		l-8.2,1.4l-0.6-3.8l8-1.3l-0.5-3l-8,1.3l-0.6-3.6l8.2-1.4l-0.5-3l-11.7,2L447,716L458.7,714.1z M351.3,716.1c3.8,0.6,6.2-1.1,7.7-3
		l-2.8-1.9c-0.9,1.3-2.6,2.1-4.4,1.8c-2.9-0.5-4.7-3.1-4.2-6.2c0.5-3.1,3-5.1,5.9-4.6c1.8,0.3,3.1,1.6,3.6,3l3.3-1
		c-0.8-2.2-2.6-4.5-6.4-5.1c-4.8-0.8-9.2,2.1-10,7.2C343.2,711.2,346.5,715.3,351.3,716.1z M320.1,723.1l-6.4-1.6l-4,16.2l6.4,1.6
		c5.1,1.2,9.4-1.1,10.6-6C327.9,728.5,325.2,724.4,320.1,723.1z M323.2,732.5c-0.7,2.8-3.1,4.6-6.3,3.8l-2.9-0.7l2.5-10.2l2.9,0.7
		C322.7,727,323.9,729.6,323.2,732.5z M327.9,742.1l11.6,2.2l0.6-3l-8.1-1.6l0.7-3.8l7.9,1.5l0.6-3l-7.9-1.5l0.7-3.6l8.1,1.6l0.6-3
		l-11.6-2.2L327.9,742.1z M356.8,730.1l-6.4,12.1l-2.7-13.4l-4-0.6l4,17.5l4.4,0.6l8.8-15.6L356.8,730.1z M470.3,725.3l2.2,10.6
		l-9.7-9.1l-3.6,0.7l3.3,16.4l3.5-0.7l-2.2-11l10.1,9.4l3.4-0.7l-3.3-16.4L470.3,725.3z M450.4,742.8l-0.6-3.8l8-1.2l-0.5-3l-8,1.2
		l-0.6-3.6l8.2-1.3l-0.5-3l-11.7,1.8l2.5,16.5l11.7-1.8l-0.5-3L450.4,742.8z M489.7,723.8l-0.8-3l-12.9,3.3l0.8,3l4.7-1.2l3.4,13.2
		l3.5-0.9L485,725L489.7,723.8z M436.7,730.8l-3,10.8l-5.1-10l-5,0.5l1.6,16.6l3.5-0.3l-1.2-12l5.9,11.5l1.5-0.2l3.5-12.4l1.2,12
		l3.6-0.4l-1.6-16.6L436.7,730.8z M360.6,747.4l11.8,1.1l0.3-3.1l-8.2-0.8l0.4-3.9l8.1,0.8l0.3-3l-8.1-0.8l0.3-3.6l8.2,0.8l0.3-3
		l-11.8-1.1L360.6,747.4z M380.3,732.3l-3.6-0.2l-0.9,16.7l10.6,0.6l0.2-3.1l-7.1-0.4L380.3,732.3z M415.9,732.5l-7.8,0.3l0.7,16.7
		l3.6-0.2l-0.3-6l4.3-0.2c3.6-0.2,5.5-2.7,5.4-5.6C421.6,734.7,419.6,732.3,415.9,732.5z M415.8,740.1l-3.8,0.2l-0.2-4.6l3.8-0.2
		c1.4-0.1,2.5,0.8,2.6,2.2C418.2,739.1,417.2,740.1,415.8,740.1z M396.9,732.6c-5,0-8.8,3.5-8.8,8.6c0,5,3.6,8.7,8.6,8.7
		c5.1,0,8.8-3.5,8.8-8.6C405.7,736.2,402,732.6,396.9,732.6z M396.8,746.7c-3.1,0-5-2.4-5-5.5c0-3.1,2-5.5,5.1-5.4
		c3.1,0,5.1,2.4,5,5.5C401.9,744.4,399.9,746.7,396.8,746.7z"
      />
      <path
        fill="#231F20"
        d="M703.9,498.3l-2.9-1l-2.7,7.8l-3.7-1.3l2.6-7.6l-2.9-1l-2.6,7.6l-3.4-1.2l2.7-7.8l-2.9-1l-3.9,11.2l15.8,5.5
		L703.9,498.3z M693,526.9c2.1-4.8,0.5-9.5-4.2-11.4c-4.6-2-9.1,0-11.2,4.8l-2.6,6.1l15.4,6.6L693,526.9z M689,528.4l-9.6-4.1
		l1.2-2.8c1.4-3.2,4.2-3.9,6.9-2.7c2.6,1.1,4,3.8,2.7,6.9L689,528.4z M676.8,553.4l3,0.5l1.9-3.4l-17.7-2.7l-2.2,3.9l11.3,13.9
		l1.9-3.4l-2-2.3L676.8,553.4z M665.6,551.3l8,1.4l-2.7,4.8L665.6,551.3z M685.8,543c2.3-4.5,0.8-7.9-2.9-9.8l-9-4.5l-1.6,3.2
		l8.9,4.4c2.1,1,2.9,2.9,1.8,5.2c-1.2,2.3-3.2,2.8-5.3,1.7l-8.9-4.4l-1.6,3.2l9,4.5C679.9,548.4,683.6,547.6,685.8,543z
		 M698.8,512.8l-15.6-6l-1.3,3.3l15.6,6L698.8,512.8z M704.2,436.5c1.1,0.1,2.4-0.2,3.7-0.7c0.8,2,1.9,3.8,4.4,4.1
		c3.2,0.3,5.1-1.9,5.4-5c0.2-1.8-0.3-3.3-1.1-4.7c0.5-0.4,1-0.8,1.4-1.1l0.4-4c-1.3,1-2.5,2-3.7,2.8c-1.2-1.1-2.5-2-3.8-2.7
		l-1.4,2.4c0.9,0.4,2.1,1,3.1,1.9c-0.6,0.4-1.2,0.8-1.8,1.2c-0.5,0.4-1.2,0.8-1.9,1.2c-0.7-2.1-1.6-4.3-4.1-4.6
		c-2.2-0.2-3.7,1.7-4,4C700.4,434.1,702,436.2,704.2,436.5z M712.4,433.3c0.5-0.3,1.4-1,2.3-1.6c0.5,0.8,0.7,1.6,0.6,2.5
		c-0.1,1.4-1.3,2.5-2.9,2.3c-1.1-0.1-1.9-0.8-2.4-1.7C710.8,434.2,711.6,433.7,712.4,433.3z M704.6,430.1c1.2,0.1,1.8,1.3,2.3,2.7
		c-0.8,0.3-1.6,0.6-2.3,0.5c-1-0.1-1.7-0.9-1.6-1.9C703.1,430.5,703.7,430,704.6,430.1z M705.8,492l-10.8-3.1l13-4.6l0.9-3.3
		l-16.1-4.6l-1,3.4l10.4,3l-12.5,4.4l-1,3.5l16.1,4.6L705.8,492z M715.6,448.7l-3-0.5l-1.4,8.1l-3.8-0.7l1.4-8l-3-0.5l-1.4,8
		l-3.6-0.6l1.4-8.1l-3-0.5l-2,11.7l16.5,2.9L715.6,448.7z M701.5,476.4c5,1.1,9.3-1.9,10.4-6.6c0.8-3.7-0.7-6.2-2.4-7.8l-2.1,2.7
		c1.2,1,1.9,2.7,1.5,4.5c-0.6,2.8-3.4,4.5-6.5,3.8c-3.1-0.7-4.9-3.4-4.2-6.2c0.4-1.8,1.8-3,3.3-3.4l-0.8-3.3c-2.3,0.7-4.7,2.3-5.6,6
		C694,470.7,696.5,475.3,701.5,476.4z M749.3,411.5l-16.7-0.9l-0.2,3.6l10.8,0.6l-11.2,7.1l-0.2,3.7l16.7,0.9l0.2-3.6l-11.2-0.6
		l11.6-7.4L749.3,411.5z M740,428.8c-5-0.6-9,2.7-9.5,7.7c-0.6,5,2.6,9.1,7.6,9.6c5,0.6,9-2.7,9.5-7.7
		C748.1,433.4,745,429.3,740,428.8z M744.5,438.1c-0.3,3.1-2.9,4.8-6,4.4c-3.1-0.3-5.2-2.6-4.9-5.6c0.3-3.1,2.9-4.8,6-4.5
		C742.6,432.8,744.8,435,744.5,438.1z M732.6,496.4l-3.7-1.1l2.2-7.8l-2.9-0.8l-2.2,7.8l-3.5-1l2.3-8l-2.9-0.8l-3.2,11.4l16.1,4.6
		l3.2-11.4l-3-0.8L732.6,496.4z M710.8,549.6c-1.9-1-4.1-0.5-5.1,1c0.4-1.6-0.2-3.4-2.1-4.3c-2-1-4.5-0.5-6,2.4l-4,7.8l14.9,7.6
		l4.1-8.1C714.1,553.2,713.1,550.8,710.8,549.6z M703.3,552.4l-2,4l-3.3-1.7l2-4c0.5-1.1,1.6-1.4,2.5-0.9
		C703.5,550.3,703.8,551.4,703.3,552.4z M709.5,555.5l-2.1,4.1l-3.5-1.8l2.1-4.1c0.6-1.2,1.8-1.5,2.7-1
		C709.8,553.2,710.2,554.3,709.5,555.5z M723.5,523.6l-3.6-1.4l2.8-7.6l-2.9-1.1l-2.8,7.6l-3.4-1.3l2.9-7.7l-2.9-1.1l-4.2,11.1
		l15.7,5.9l4.2-11.1l-2.9-1.1L723.5,523.6z M719.4,528.9c-3.4-1.5-5.5,1.4-7.2,3.7c-1.2,1.6-2,2.7-3.1,2.3c-0.9-0.4-1.2-1.5-0.6-2.9
		c0.6-1.5,1.9-2.9,3.6-3.7l-1.6-2.8c-2.1,0.9-3.7,2.6-4.7,5.1c-1.5,3.6-0.4,6.7,2.1,7.8c3.4,1.5,5.5-1.5,7.1-3.7
		c1.2-1.6,2.1-2.8,3.3-2.3c0.9,0.4,1.4,1.6,0.6,3.4c-0.9,2.1-2.6,3.4-4.2,4l1.7,2.9c2.1-0.8,4-2.5,5.2-5.5
		C723.5,533,722.2,530.1,719.4,528.9z M728.5,509.5l-12.9-4.3l-1.1,3.4l15.9,5.3l3.3-10.1l-3-1L728.5,509.5z M689.6,584.6
		c2-3,1-6-1.4-7.6c-2.5-1.6-5.6-1.3-7.6,1.8l-4.2,6.6l14,9.1l1.9-3l-5-3.2L689.6,584.6z M686.8,583.4l-2,3.2l-3.9-2.5l2.1-3.2
		c0.8-1.2,2.1-1.6,3.3-0.8C687.3,580.8,687.5,582.2,686.8,583.4z M700.8,564.4l-8.7-5l-1.8,3.1l8.6,4.9c2,1.2,2.8,3.1,1.5,5.3
		c-1.3,2.3-3.3,2.6-5.3,1.4l-8.6-4.9l-1.8,3.1l8.7,5c3.6,2,7.2,1.4,9.8-3C705.7,569.9,704.5,566.4,700.8,564.4z M736.3,473
		c1.2,1,1.9,2.7,1.4,4.5c-0.7,2.8-3.4,4.4-6.5,3.7c-3.1-0.7-4.8-3.4-4.2-6.2c0.4-1.8,1.8-3,3.3-3.4l-0.8-3.3c-2.3,0.7-4.7,2.3-5.6,6
		c-1.1,4.8,1.4,9.3,6.4,10.5c5,1.2,9.3-1.8,10.4-6.6c0.9-3.7-0.6-6.2-2.4-7.9L736.3,473z M728.8,451.1l16.5,2.5l0.5-3.5l-16.5-2.5
		L728.8,451.1z M731.5,453.9l-3.1-0.6l-2.4,13.1l3.1,0.6l0.9-4.8l13.4,2.4l0.6-3.5l-13.4-2.4L731.5,453.9z"
      />
      <path
        fill="#231F20"
        d="M239.6,93.8c2-0.5,3.6-0.8,4.2,0.3c0.5,0.9,0,2.2-1.8,3.2c-2.2,1.1-4.4,1.1-6.1,0.5l-0.5,3.5
		c2.2,0.7,4.9,0.6,7.9-1c4.2-2.2,5.1-5.4,3.6-8.3c-1.8-3.5-5.5-2.5-8.4-1.8c-2,0.5-3.5,0.9-4-0.2c-0.5-0.9,0-2,1.5-2.8
		c1.5-0.8,3.5-1.1,5.4-0.6l0.6-3.4c-2.3-0.6-4.7-0.3-7.2,1c-3.7,1.9-4.8,5.2-3.5,7.8C233.1,95.5,236.8,94.5,239.6,93.8z
		 M169.1,152.4l-1.2-3l5.8-5.3l2.9,1.5l3-2.7l-16.8-8.5l-3.5,3.1l6.8,17.6L169.1,152.4z M170.7,142.4l-4.3,3.9l-3.2-7.9L170.7,142.4
		z M156.3,164.9l-8.4-8.3l14.3,2.3l2.5-2.6L152.3,144l-2.6,2.7l8.1,8l-13.8-2.2l-2.7,2.7l12.5,12.4L156.3,164.9z M189.6,134.7
		l-2.1-2.5l-5.7,4.7l-9.1-11l-2.9,2.4l11.2,13.6L189.6,134.7z M256.6,82.5c-1.4,1.7-2.5,3.7-1.4,6.2c1.4,3.1,4.4,3.6,7.4,2.3
		c1.7-0.8,2.9-2,3.7-3.4c0.7,0.3,1.3,0.5,1.7,0.7l3.9-1.7c-1.6-0.7-3.1-1.3-4.5-1.9c0.4-1.6,0.5-3.3,0.5-4.9l-3-0.1
		c0.1,1.1,0.1,2.4-0.1,3.8c-0.7-0.3-1.4-0.7-2.1-1c-0.6-0.3-1.4-0.7-2.1-1.1c1.6-1.8,3.1-3.7,2-6.2c-0.9-2.2-3.4-2.6-5.7-1.6
		c-2.7,1.2-3.9,3.7-2.9,5.9C254.5,80.6,255.4,81.6,256.6,82.5z M261.1,85.3c0.6,0.3,1.6,0.8,2.7,1.2c-0.5,0.8-1.1,1.5-2,1.9
		c-1.3,0.6-2.9,0.1-3.6-1.4c-0.5-1.1-0.2-2.1,0.3-3.1C259.4,84.4,260.3,84.9,261.1,85.3z M258,75.8c0.8-0.4,1.6-0.1,2,0.7
		c0.5,1.2-0.2,2.3-1.3,3.6c-0.7-0.6-1.4-1.2-1.6-1.8C256.7,77.3,257.1,76.2,258,75.8z M229.3,108.1c3.5-2,4.3-5,4.3-7.5l-3.6,0.3
		c0.1,1.6-0.7,3.4-2.4,4.4c-2.6,1.6-5.9,0.6-7.6-2.3c-1.7-2.9-0.9-6.2,1.7-7.7c1.7-1,3.6-0.8,5,0l2-3c-2.2-1.3-5.2-1.9-8.6,0.1
		c-4.4,2.6-6.1,7.9-3.3,12.5C219.4,109.6,224.8,110.7,229.3,108.1z M218.8,114l-9.5-14.8l-3.2,2l9.5,14.8L218.8,114z M193.1,132.1
		l3-2.3l-4.4-5.7l-1-12.3l-3.4,2.6l0.8,8.2l-7.8-3l-3.4,2.6l11.7,4.2L193.1,132.1z M198.1,110.7l8.1,11.8l3.1-2.1l-8.1-11.8l4.2-2.9
		l-1.9-2.7l-11.6,8l1.9,2.7L198.1,110.7z M146.7,170.8l3,1.2l2.7-3.1l-17.7-6.6l-3.1,3.5l8.7,16.8l2.7-3.1l-1.5-2.8L146.7,170.8z
		 M139.7,173.8l-4.1-7.5l8,3.2L139.7,173.8z M225.7,126.4l3.6,18.5l3.5-2.2l-0.7-3.2l6.7-4.2l2.5,2l3.5-2.2l-15.1-11.3L225.7,126.4z
		 M231.2,136.3l-1.8-8.4l6.7,5.3L231.2,136.3z M248.8,116.6l-1.6-2.9l-12.2,6.9l1.6,2.9l4.5-2.5l7,12.5l3.3-1.8l-7-12.5L248.8,116.6
		z M249.7,112.5l8.1,15.6l3.3-1.7l-8.1-15.6L249.7,112.5z M296.7,111l-6.3-16.4l-3.5,1.3l4.1,10.6l-11.7-7.7l-3.6,1.4l6.3,16.4
		l3.5-1.4l-4.3-11l12.1,8L296.7,111z M219.9,147.5l0.3-14l-1.7-2.5l-10.8,7.6l1.9,2.7l6.8-4.8l-0.3,14l1.7,2.5l10.9-7.7l-1.9-2.7
		L219.9,147.5z M264,105.1c-4.8,2.2-6.7,7.3-4.5,12.1c2.2,4.8,7.3,6.6,12.1,4.4c4.8-2.2,6.8-7.3,4.5-12.1
		C274,104.7,268.9,102.9,264,105.1z M270.3,118.6c-2.9,1.4-5.9,0-7.3-3c-1.4-3-0.5-6.1,2.4-7.5c2.9-1.4,5.9,0,7.3,3
		C274.1,114.1,273.2,117.3,270.3,118.6z M178.6,162.8l11.9,12.9l2.8-2.5l-11.9-12.9L178.6,162.8z M202.1,142.9l10.7,14l3-2.3
		l-10.7-14L202.1,142.9z M169.5,179.7c-2.3-2.1-5.6-2.3-8.2,0.5l-5.5,6.1l13,11.8l2.5-2.8l-4.7-4.2l3-3.3
		C172.2,185.1,171.7,181.8,169.5,179.7z M166.9,186.1l-2.7,2.9l-3.6-3.3l2.7-2.9c1-1.1,2.5-1.3,3.5-0.3
		C168,183.5,167.9,185,166.9,186.1z M160.4,194.4c-4.1-3.3-9.5-2.7-12.9,1.4c-3.3,4.1-2.8,9.5,1.3,12.9c4.1,3.3,9.5,2.7,12.8-1.4
		C165,203.1,164.5,197.7,160.4,194.4z M159.1,205.1c-2,2.5-5.3,2.6-7.8,0.5c-2.6-2.1-3.2-5.3-1.1-7.8c2-2.5,5.3-2.6,7.9-0.5
		C160.5,199.4,161.1,202.6,159.1,205.1z M194.8,148.6l3.8,11.2l-10.3-5.7l-4,3.4l11.3,13.5l2.9-2.4l-8.1-9.7l11.9,6.5l1.3-1.1
		l-4.3-12.9l8.1,9.7l2.9-2.4l-11.3-13.5L194.8,148.6z M178.8,167.1l-2.3-2.3l-10,9.9l2.3,2.3l3.7-3.6l10.1,10.1l2.7-2.7l-10.1-10.1
		L178.8,167.1z"
      />
      <g>
        <g>
          <path
            fill="#F5B336"
            d="M910.6,285.5c1.9,0,1.9-3,0-3C908.7,282.5,908.7,285.5,910.6,285.5L910.6,285.5z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ApproachDiagram;
